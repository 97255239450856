import {Component, OnInit} from '@angular/core';
import {Contact} from '../../domain/contact';
import {ContactApiService} from '../../services/contact-api.service';

@Component({
  selector: 'bridge-kontakte',
  templateUrl: './kontakte.component.html',
  styleUrls: ['./kontakte.component.scss'],
})
export class KontakteComponent implements OnInit {
  contacts: Contact[] = [
    {
      title: '1. Vorsitzender',
      name: 'Pieter van der Eijk',
      email: 'vandereijk@t-online.de',
      telephone: '02152 / 893149'
    },
    {
      title: 'Kassenwartin',
      name: 'Beatrix Schmitz',
      email: 'beat.schmitz@web.de',
      telephone: '02152 / 9947820'
    },
    {
      title: 'Schriftführerin',
      name: 'Angelika Sleegers',
      email: 'a.sleegers@gmx.de',
      telephone: '02152 / 8761424'
    },
  ];

  constructor(private contactService: ContactApiService) {
  }

  ngOnInit() {
    // this.contactService.getAll().subscribe(contacts => this.contacts = contacts);
  }

  getImage(index: number): string {
    const imageIndex = index % 5 + 1;
    return "/assets/kontakte/karte_" + imageIndex + ".png";
  }

  get contactColumns(): Contact[][] {
    const res: Contact[][] = [];
    this.contacts.forEach((contact, index) => {
      const column = index % 3;
      if (column + 1 > res.length) {
        res.push([]);
      }
      res[column].push(contact);
    });
    return res;
  }
}
